import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import {
  Box,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress
} from '@mui/material';
import NetworkPingIcon from '@mui/icons-material/NetworkPing'; // NEW: Icon for "pinging"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import WifiIcon from '@mui/icons-material/Wifi';
import DeviceTabs from '../../components/DeviceTabs';
import Page404 from '../Page404';

const DeviceLogs = () => {
  const { id } = useParams();
  const [deviceLogs, setDeviceLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeviceLogs = async () => {
      setLoading(true);
      try {
        const logsSnapshot = await db
        .collection('devices')
        .doc(id)
        .collection('log')
        .orderBy('time', 'desc')  // Sort by 'time' in descending order (latest first)
        .limit(100)               // Limit to 100 logs
        .get();

        const logs = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDeviceLogs(logs);
      } catch (error) {
        setError('Failed to load device logs');
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceLogs();
  }, [id]);

  // Icon selector based on log type
  const getLogIcon = (type) => {
    switch (type) {
      case 'error':
        return <ErrorIcon color="error" />;
      case 'info':
        return <InfoIcon color="primary" />;
      case 'sound':
        return <VolumeUpIcon color="secondary" />;

      case 'pinging': // NEW: Icon for "pinging"
      return <NetworkPingIcon color="warning" />;
      case 'connected':
        return <WifiIcon color="success" />;
      default:
        return <NetworkPingIcon color="warning"  />;
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: '100%' }}>
      {deviceLogs.length > 0 ? (
        deviceLogs.map((log) => (
          <Accordion key={log.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {getLogIcon(log.type)}
              <Typography sx={{ marginLeft: 1 }}>
                {log.time && new Date(log.time.seconds * 1000).toLocaleString()}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {typeof log.message === 'string' || typeof log.message === 'number'
                  ? String(log.message)
                  : JSON.stringify(log.message, null, 2)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Alert severity="info">No logs available for this device.</Alert>
      )}
    </Box>
  );
};

export default DeviceLogs;
