import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import DevicesPage from './pages/DevicesPage';

import DeviceOverviewPage from './pages/Device/OverviewPage';
import DeviceLogPage from './pages/Device/LogPage';
import DeviceSchedulePage from './pages/Device/SchedulePage';
import DeviceSettingsPage from './pages/Device/SettingsPage';
import DeviceUsersPage from './pages/Device/UsersPage';

import Page404 from './pages/Page404';
import SoundsPage from './pages/SoundsPage';
import DashboardPage from './pages/DashboardPage';
import SharedPage from './pages/SharedPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import { auth } from './firebase';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';  // Import the custom theme
import Loading from './components/Loading';
import DeviceTabs from './components/DeviceTabs'; // Import DeviceTabs



//import { doc, getFirestore } from 'firebase/firestore';


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading/>;
  }

  if(!user)
    return (<LoginPage />)

  return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<DashboardPage />} />

          <Route path="/share/:deviceId/:deviceShareKey" element={<SharedPage />} />

          <Route path="/devices" element={<DevicesPage />} />

          
        <Route path="/device/:id" element={<DeviceTabs />}>
          <Route index element={<DeviceOverviewPage />} />
          <Route path="log" element={<DeviceLogPage />} />
          <Route path="schedule" element={<DeviceSchedulePage />} />
          <Route path="settings" element={<DeviceSettingsPage />} />
          <Route path="users" element={<DeviceUsersPage />} />
          <Route path="*" element={<Page404/>} />           
        </Route>
        


          <Route path="/sounds" element={<SoundsPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="*" element={<Page404/>} />           
        </Routes>
      </Router>
  );
}

export default App;
