import React, { useState, useEffect } from 'react';
import {
  List, ListItem, TextField, DialogActions, DialogContentText, Toolbar, ListItemText, Dialog, DialogTitle,
  Alert, IconButton, Button, DialogContent, CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../components/Loading';
import { db } from '../firebase'; // Import Firestore configuration
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TimeAgo from 'react-timeago';

const DevicesPage = () => {
  const auth = getAuth();
  const navigate = useNavigate(); // useNavigate for redirecting after success
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false); // Loading state for the API call
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const [connectKey, setConnectKey] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(null);

  // Fetch devices from Firestore where the user has permission
  const fetchDevices = async () => {
    setLoading(true);
    try {
      const currentUser = auth.currentUser;

      const devicesSnapshot = await db.collection('devices')
        .where(`users.${currentUser.uid}`, '!=', null) // Filter devices based on current user
        .get();

      const devicesList = devicesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(devicesList)

      setDevices(devicesList);
      setLoading(false);
    } catch (error) {
      setError('Failed to load devices.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices(); // Fetch devices when component mounts
  }, [auth]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setError(null); // Reset error state when closing dialog
  };

  // Function to connect the device using deviceId and connectKey
  const handleConnectDevice = async () => {
    setApiLoading(true); // Set loading state during API call
    setError(null); // Reset error state before starting
    try {
      const token = await auth.currentUser.getIdToken(true);

      const config = {
        method: 'post',
        data: {
          deviceId: deviceId,
          connectKey: connectKey
        },
        url: 'https://dashboard.api.hidenbeep.com/devices/connect',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
      };

      const response = await axios(config);

      // Assuming the device connection is successful, navigate to the device page
      if (response.status === 200) {
        setOpenDialog(false);
        fetchDevices(); // Refresh devices after connecting
        navigate(`/device/${deviceId}`); // Redirect to the connected device page
      } else {
        setError('Failed to connect device.');
      }
    } catch (error) {
      setError('Failed to connect device.');
      console.error('Error connecting device:', error);
    } finally {
      setApiLoading(false); // Reset loading state after API call
    }
  };

  const handleOpenDeleteDialog = (device) => {
    setDeviceToDelete(device);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeviceToDelete(null);
  };

  const handleDeleteDevice = async () => {
    try {
      await db.collection('devices').doc(deviceToDelete.id).delete();
      setDevices(devices.filter(device => device.id !== deviceToDelete.id));
      setDeleteDialogOpen(false);
      setDeviceToDelete(null);
    } catch (error) {
      setError('Failed to delete device.');
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Toolbar style={{ backgroundColor: '#373737', borderBottom: '1px solid rgba(81, 81, 81, 1)' }}>
        <IconButton color="primary" onClick={handleDialogOpen}>
          <AddIcon /> {/* Connect new device button */}
        </IconButton>
        <h2>Devices</h2>
      </Toolbar>

      {error && <Alert severity="error">{error}</Alert>}

      <List>
        {
devices.map((device, index) => {
  const isActive = device.lastConnected
    ? new Date().getTime() - new Date(device.lastConnected.seconds * 1000).getTime() <= 3600000 // 1 hour
    : false;

  return (
    <ListItem key={index} button component={Link} to={`/device/${device.id}`}>
      <ListItemText
        primary={device.name}
        secondary={
          <>
            Status:{" "}
            {isActive ? (
              <span style={{ color: "Green" }}>Online</span>
            ) : (
              <span style={{ color: "Red" }}>Offline</span>
            )}
            <br />
            Next Beep:{" "}
            {device?.nextBeep?.seconds ? (
              <TimeAgo date={new Date(device.nextBeep.seconds * 1000)} />
            ) : (
              "No beep scheduled"
            )}
          </>
        }
      />
    </ListItem>
  );
})}
      </List>

      {/* Connect Device Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Connect New Device</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Device ID"
            fullWidth
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Device Key"
            fullWidth
            value={connectKey}
            onChange={(e) => setConnectKey(e.target.value)}
          />
          {apiLoading ? (
            <CircularProgress sx={{ mt: 2 }} />
          ) : (
            <Button onClick={handleConnectDevice} color="primary" sx={{ mt: 2 }}>
              Connect Device
            </Button>
          )}
        </DialogContent>
      </Dialog>

      {/* Confirmation dialog for deleting device */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Device</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the device "{deviceToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteDevice} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DevicesPage;
