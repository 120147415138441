// HomePage.js
import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Grid, List, ListItem, ListItemText, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { db } from '../firebase'; // Assuming you're using Firestore for fetching devices
import TimeAgo from 'react-timeago';

const HomePage = () => {
  const [devices, setDevices] = useState([]);
  const [stats, setStats] = useState({ totalDevices: 0, activeDevices: 0 });

  useEffect(() => {
    // Fetch devices from Firestore (replace with your own logic)
    const fetchDevices = async () => {
      const devicesCollection = await db.collection('devices').get();

      
      const devicesData = devicesCollection.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      //const devicesData = devicesCollection.docs.map((doc) => doc.data());
      setDevices(devicesData);

      // Calculate stats based on fetched devices
      const totalDevices = devicesData.length;
      const activeDevices = devicesData.filter((device) => device.isActive).length;
      setStats({ totalDevices, activeDevices });
    };

    fetchDevices();
  }, []);

  return (
    <Box p={3}>
      {/* Stats Section */}
      <Box mb={4}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Total Devices: {stats.totalDevices}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Active Devices: {stats.activeDevices}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Links Section */}
      <Box mb={4} sx={{textAlign:"center"}}>
        <Button variant="contained" color="primary" component={Link} to="/devices" style={{ marginRight: '10px' }}>
          Devices
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/sounds">
          Sounds
        </Button>
      </Box>

      {/* Devices List */}
      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          Recent Devices
        </Typography>
        <List>
          {
devices.map((device, index) => {
  const isActive = device.lastConnected
    ? new Date().getTime() - new Date(device.lastConnected.seconds * 1000).getTime() <= 3600000 // 1 hour
    : false;

  return (
    <ListItem key={index} button component={Link} to={`/device/${device.id}`}>
      <ListItemText
        primary={device.name}
        secondary={
          <>
            Status:{" "}
            {isActive ? (
              <span style={{ color: "Green" }}>Online</span>
            ) : (
              <span style={{ color: "Red" }}>Offline</span>
            )}
            <br />
            Next Beep:{" "}
            {device?.nextBeep?.seconds ? (
              <TimeAgo date={new Date(device.nextBeep.seconds * 1000)} />
            ) : (
              "No beep scheduled"
            )}
          </>
        }
      />
    </ListItem>
  );
})}
        </List>
      </Box>
    </Box>
  );
};

export default HomePage;
