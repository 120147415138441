import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth } from '../../firebase';  // Import auth to get current user
import {
  Box,
  Alert,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const UsersPage = () => {
  const { id } = useParams(); // Device ID
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('user');
  const [actionError, setActionError] = useState(null);

  const currentUserId = auth.currentUser?.uid;  // Get current user ID

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const deviceRef = await db.collection('devices').doc(id).get();
        const userData = deviceRef.data().users || {};

        const usersArray = Object.entries(userData).map(([userId, userInfo]) => ({
          id: userId,
          ...userInfo
        }));
        setUsers(usersArray);
      } catch (error) {
        setError('Failed to load users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [id]);

  // Add a new user
  const handleAddUser = async () => {
    /*if (!newUserEmail) {
      setActionError('Email is required');
      return;
    }

    try {
      const newUserId = db.collection('users').doc().id; // Generate a new user ID
      await db.collection('devices').doc(id).update({
        [newUserId]: {
          email: newUserEmail,
          permission: newUserRole,
          linkedAt: new Date().toISOString(),
        },
      });

      setUsers([...users, {
        id: newUserId,
        email: newUserEmail,
        permission: newUserRole,
        linkedAt: new Date().toISOString()
      }]);

      setNewUserEmail('');
      setNewUserRole('user');
      setActionError(null);
    } catch (error) {
      setActionError('Failed to add user');
    }*/
  };

  // Remove a user (Prevent self-removal)
  const handleRemoveUser = async (userId) => {
    if (userId === currentUserId) {
      setActionError("You can't remove yourself.");
      return;
    }

    try {
      await db.collection('devices').doc(id).update({
        [`users.${userId}`]: window.firebase.firestore.FieldValue.delete(),
      });

      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      setActionError('Failed to remove user');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Device Users
      </Typography>

      {/* Add User Form */}
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          label="User Email"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          fullWidth
        />
        <Select
          value={newUserRole}
          onChange={(e) => setNewUserRole(e.target.value)}
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="owner">Owner</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddUser}
        >
          Add User
        </Button>
      </Box>

      {actionError && <Alert severity="error">{actionError}</Alert>}

      {/* Users Table */}
      {users.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Linked At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.permission}</TableCell>
                  <TableCell>{new Date(user.linkedAt).toLocaleString()}</TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveUser(user.id)}
                      disabled={user.id === currentUserId}  // Disable button for current user
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="info">No users found for this device.</Alert>
      )}
    </Box>
  );
};

export default UsersPage;
